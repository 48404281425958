import React,{ useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import xss from 'xss';

function RouteWrapper({
  component: Component,
  layout: Layout,
  isPrivate = false,
  ...rest
}) {
  const [isLoading, setLoading] = React.useState(true); // Loading state
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const [isIntegrated, setIsIntegrated] = React.useState(false);
  const [isLoggedOut, setIsLogout] = React.useState(false);
  useEffect(() => {
    const jwtToken = new URLSearchParams(window.location.search).get("token");
    // check whether token present in url or not
    const code = new URLSearchParams(window.location.search).get("code");
    setAuthorizationToken(jwtToken, code).then(authorised=>{
      if(authorised) {
        setIsAuthorized(authorised);
        setLoading(false);
      }else if (checkIntegratedRequest(window.location.search)){
        setLoading(false);
        setIsIntegrated(true)
      }else {
        setLoading(false);
        setIsLogout(true)
      }
    })
    
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        (!isLoading && isAuthorized)?  
          <Layout {...props}>
            <Component {...props} />
          </Layout> :  
        (!isLoading && isLoggedOut) ? <Redirect to={{ pathname: "/login", state: { from: props.location } }}/> : ""
      }
    />
  );
}

async function setAuthorizationToken(jwtToken, code){
  try{
    const loggedIn = localStorage.getItem("loggedIn");
    if (!loggedIn) return loggedIn;
    // check token present in localStorage or not
    if (localStorage.getItem("token") === null) {
      if (jwtToken !== undefined && jwtToken) {
        const decodedJwtToken = jwt_decode(jwtToken);
        localStorage.setItem("token", jwtToken);
        localStorage.setItem("user", decodedJwtToken["p-uname"]);
        localStorage.setItem("userEmail", decodedJwtToken["p-email"]);
        // let accessGroups =
        //   decodedJwtToken["p-claims-allow"]["dss permission group"];
        // localStorage.setItem("accessGroups", JSON.stringify(accessGroups));
        return true;
      }
      else if (code !== undefined && code){
          let requestOptions = {
            method: 'get',
            maxBodyLength: Infinity,
            url: window._env_["REACT_APP_PALADIN_GET_TOKEN_CALLBACK_API"]+code,
            headers: { 
              'x-api-key': window._env_["REACT_APP_PALADIN_APP_ID"]
            }
          };
          requestOptions = JSON.parse(xss(JSON.stringify(requestOptions)));
          const response = await axios.request(requestOptions)
          if(response && response.data && response.data.token && response.data.token.length){
                const decodedJwtToken = jwt_decode(response.data.token);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("user", decodedJwtToken["p-uname"]);
                localStorage.setItem("userEmail", decodedJwtToken["p-email"]);
                return true;
              }else return false;
      }else return false;
      
      // let accessGroups =
      //   decodedJwtToken["p-claims-allow"]["dss permission group"];
      // localStorage.setItem("accessGroups", JSON.stringify(accessGroups));    
    } else return loggedIn;
  }catch(error){
    console.log("setAuthorizationToken of catch block......... ", error)
    return false;
  }
  
}

function checkIntegratedRequest(searchUrl) {
  const allowedAssetsList = ['DCC'];
  let searchParams = new URLSearchParams(window.location.search);
  if((window.location.pathname === '/deviceDetails') && allowedAssetsList.indexOf(searchParams.get('asset')) > -1){
    localStorage.setItem("loggedIn", "true");
    const state = {id:searchParams.get('id'), asset:searchParams.get('asset')};
    window.location.href = window._env_["REACT_APP_PALADIN_GET_TOKEN_API"]+'&state='+ JSON.stringify(state);
    return true;
  }else {
    return false;}

}
export default RouteWrapper;
